import { useContext, useEffect, useState } from 'react'; 
import audio1 from '../../audios/perro.mp3';
import palabra1 from '../../images/palabra_perro-1.png';
import palabra2 from '../../images/palabra_perro-2.png';
import palabra3 from '../../images/palabra_perro-3.png';
import palabra4 from '../../images/palabra_perro-4.png';
import palabra5 from '../../images/palabra_perro-5.png';
import palabra6 from '../../images/palabra_perro-6.png';
import btn_next from '../../images/botones_siguiente.png';
import btn_back from '../../images/botones_anterior.png';
import btn_validate from '../../images/btn_comprobar.png';
import btn_reiniciar from '../../images/boton_reiniciar.png';
import CorrectoPopUp from '../mensajes popUp/correcto/CorrectoPopUp';
import ContextProvider  from '../../context/ContextProvider';
import { Link } from 'react-router-dom';


import './Palabras.scss';
import IncorrectoPopUp from '../mensajes popUp/incorrecto/IncorrectoPopUp';

const Palabras = () => {
    const {correcto, incorrecto, setCorrecto, setIncorrecto} = useContext(ContextProvider);
    
    const [selec, setSelec] = useState([]);
    const [itemSelec, setItemSelect] = useState([]);
    
    const seleccion = (e) => {
        setSelec([e.target, ...selec]);        
        setItemSelect([e.target.id, ...itemSelec]);        
    }

    selec.map(item => item.className = 'palabraSelecciona');

    const validaRespeusta = () => {
        console.log('Validando');
        const valoresCorrecto = ['hierro', 'grosero', 'enredo', 'duelo'];

        valoresCorrecto.sort();
        itemSelec.sort();

        console.log(valoresCorrecto);
        console.log(itemSelec);

        let encuentra = false;
        for(var i =0; i < valoresCorrecto.length;i++){
            encuentra = false;
            for(var j =0; j < itemSelec.length;j++){
                if(valoresCorrecto[i] === itemSelec[j]){
                    encuentra = true;
                    break;
                }
            }
            if(!encuentra){
            console.log("los arreglos no son iguales");
            setIncorrecto(true);
            break;
            }
        }
        if(encuentra){
            console.log("si son iguales");
            setCorrecto(true);
        }
    }

    useEffect(() => {
        // console.log(selec);
    }, [selec]);
    
    return (
        <div className='contenido_palabras'>
            {
                (correcto) ? <CorrectoPopUp></CorrectoPopUp> : null
            }

            {
                (incorrecto) ? <IncorrectoPopUp></IncorrectoPopUp> : null
            }
            
            <div className='audio'>
                <audio controls>
                    <source src={audio1}></source>
                </audio>
            </div>
            <div className='plabras_respuestas'>
                <img src={palabra1} alt='palabra' id='abrazo' onClick={e => seleccion(e)}/>
                <img src={palabra2} alt='palabra' id='rechazo' onClick={e => seleccion(e)}/>
                <img src={palabra3} alt='palabra' id='adorno' onClick={e => seleccion(e)}/>
                <img src={palabra4} alt='palabra' id='mercado' onClick={e => seleccion(e)}/>
                <img src={palabra5} alt='palabra' id='aderezo' onClick={e => seleccion(e)}/>
                <img src={palabra6} alt='palabra' id='escaso' onClick={e => seleccion(e)}/>
            </div>
            <div className='botones'>
                <Link className='enlace' to='/'><img src={btn_back} alt='btn'/></Link>
                <img src={btn_validate} alt='btn' onClick={() => validaRespeusta()}/>
                <Link className='enlace' reloadDocument ><img src={btn_reiniciar} alt='btn'/></Link>
                <Link className='enlace' to='/pteclado'><img src={btn_next} alt='btn'/></Link>
            </div>
        </div>
    )
}

export default Palabras;