import { useContext } from 'react'; 
import fondoCorrecto from '../../../images/ventana_correcto.png';
import BotonCerrar from '../../../images/btn_cerrar.png';
import ContextProvider from '../../../context/ContextProvider';

import '../estilosPopUp.scss';

const CorrectoPopUp = () => {

  const {setCorrecto} = useContext(ContextProvider);

  const cerrar = () => {
    setCorrecto(false);
  }

  return (
    <div className='popUp_correcto'>
        <img className='ventana' src={fondoCorrecto} alt='imagen'/>
        <img className='btn_cerrar' src={BotonCerrar} alt='imagen' onClick={() => cerrar()}/>
    </div>
  )
}

export default CorrectoPopUp;