import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import { ContextProvider } from './context/ContextProvider';
import Layout from './Layout/Layout';

import Palabras from './components/palabra_rima/Palabras';
import PalabrasPerro from './components/palabra_rima/PalabraPerro';
import PalabrasTeclado from './components/palabra_rima/PalabrasTeclado';
import PalabrasMono from './components/palabra_rima/PalabrasMono';

import './App.scss';

const App = () => {
    return (
        <ContextProvider>
            <Router>
                <Routes>
                    <Route element={<Layout />}>
                        <Route exac path='/' element={<Palabras />}/>
                        <Route exac path='/pperro' element={<PalabrasPerro />}/>
                        <Route exac path='/pteclado' element={<PalabrasTeclado />}/>
                        <Route exac path='/pmono' element={<PalabrasMono />}/>
                    </Route>
                </Routes>
            </Router>
        </ContextProvider>
    )
}

export default App